import { computed, defineComponent, ref, toRefs, watch } from 'vue';
export default defineComponent({
  name: 'TTableToolBox',
  props: {
    // 完整的表格数据
    tableData: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    // 每条数据的唯一索引
    rowKey: {
      type: String,
      default: 'id'
    },
    isGroup: {
      type: Boolean,
      default: true
    },
    defaultBatchSelectedText: {
      type: String,
      default: '已选择{n}条数据'
    }
  },
  emits: {
    selectAll: null
  },
  setup(props, context) {
    const {
      tableData,
      selectedRows,
      rowKey,
      defaultBatchSelectedText
    } = toRefs(props);
    const selectedText = computed(() => defaultBatchSelectedText.value.replace('{n}', selectedRows.value.length.toString()));
    const selectedRowIds = computed(() => {
      return selectedRows.value.map(selectedRow => selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow[rowKey.value]);
    });
    const checkAll = ref(false);
    watch(selectedRowIds, newVal => {
      // 用户全选中所有的选择框后，改变全选框的状态为选中状态
      if (tableData.value.every(dataItem => newVal.includes(dataItem === null || dataItem === void 0 ? void 0 : dataItem[rowKey.value]))) {
        checkAll.value = true;
      }
      // 用户取消选中所有的选择框后，改变全选框的状态为非选中状态
      else {
        checkAll.value = false;
      }
    });
    const isIndeterminate = computed(() => {
      if (checkAll.value) {
        return false;
      }
      return !!(selectedRowIds.value instanceof Array && selectedRowIds.value.length);
    });
    const handleCheckAllChange = value => {
      // 用户点击全选按钮，全选数据
      if (value) {
        context.emit('selectAll', tableData.value);
      }
      // 用户取消全选
      else {
        context.emit('selectAll', []);
      }
    };
    return {
      selectedText,
      checkAll,
      isIndeterminate,
      handleCheckAllChange
    };
  }
});