import {isObject} from './object';

/**
 * 深度优先算法遍历树形结构的数据
 * @param origin 传入一个树形解构的数据，如 [{value: 1, children: [{value: 11}, {value: 12}]}, {value: 2}]
 * @param childrenIndex 子节点字段的索引，默认为 'children'
 * @param onSearchedNode 遍历到子节点时触发
 * @returns
 * @author jiguangrui(jiguangrui@baidu.com)
 * @date 2021-09-15
 */
export function dfsTree<T = {[key: string]: any}>(
    origin: T[],
    childrenIndex: string = 'children',
    onSearchedNode?: (node: T, index: number) => void
): T[] {
    const result: T[] = [];
    if (origin instanceof Array) {
        origin.forEach((item, index) => {
            if (isObject(item)) {
                result.push(item);
                if (typeof onSearchedNode === 'function') {
                    onSearchedNode(item, index);
                }
                if (item?.[childrenIndex] instanceof Array) {
                    result.concat(dfsTree(item[childrenIndex], childrenIndex, onSearchedNode));
                }
            }
        });
    }
    return result;
}

/**
 * 根据某些属性条件过滤属性结构
 * @param {source: Array} 原始数组
 * @param {childrenName: String} 子节点属性字段名
 * @param {prop: string} 要根据那个属性名过滤
 * @param {val: any} 要过滤的那个属性是什么值时才过滤掉
 *
 * @return {result: Arran} 返回根据条件过滤掉某节点的新数据
 */
export function filterTreeExcludeByProp<T = {[key: string]: any}>(
    source: T[],
    childrenName: string,
    prop: keyof T,
    val: any
) {
    let result: T[] = [];
    if (Array.isArray(source)) {
        source.forEach(item => {
            if (item[prop] !== val) {
                if (Array.isArray(item[childrenName])) {
                    item[childrenName] = filterTreeExcludeByProp(item[childrenName], childrenName, prop, val);
                }
                result.concat(result.push(item) as any);
            }
        });
    }
    return result;
};
