import {COMPANY_DEFAULT_LOGO_STYLES} from './config';

/**
 * 获取公司默认logo
 * @param companyName 公司名
 */
export function getCompanyDefaultLogo(companyName?: string): string {
    let result = '';
    if (companyName && companyName.length) {
        const canvas = document.getElementById('companyDefaultLogo') as HTMLCanvasElement | null;
        const ctx = canvas?.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvas!.width, canvas!.height);
            // 根据首字符十进制的unicode值确定样式
            const styleIndex = companyName.charCodeAt(0) % COMPANY_DEFAULT_LOGO_STYLES.length;
            const style = COMPANY_DEFAULT_LOGO_STYLES[styleIndex];
            ctx.beginPath();
            ctx.fillStyle = style.backgroundColor;
            ctx.arc(60, 60, 60, 0, Math.PI * 2);
            ctx.fill();
            ctx.beginPath();
            ctx.fillStyle = style.color;
            ctx.font = 'bold 72px sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(companyName.substring(0, 1), 60, 60);
            ctx.fill();
            result = canvas!.toDataURL('image/png');
        }
    }
    return result;
}
