import {debounce, DebouncedFunc} from 'lodash';
import {onUnmounted, ref} from 'vue';

export interface IDebounceOptions {
    wait?: number;
    leading?: boolean;
    trailing?: boolean;
}

type Fn = (...args: any) => any;

/**
 * 用来处理防抖函数的 Hook，移植自 umi-hook/useDebounceFn
 * https://ahooks.js.org/zh-CN/hooks/side-effect/use-debounce-fn
 * @author jiguangrui@baidu.com
 */
function useDebounceFn<T extends Fn>(fn: T, options?: IDebounceOptions) {
    const wait = options?.wait ?? 1000;
    const debounceRef = ref<DebouncedFunc<T>>(
        debounce<T>(
            ((...args: any[]) => {
                return fn(...args);
            }) as T,
            wait,
            options
        )
    );

    onUnmounted(() => {
        debounceRef.value.cancel();
    });

    return {
        run: debounceRef.value as unknown as T,
        cancel: debounceRef.value.cancel,
        flush: debounceRef.value.flush,
    };
}

export default useDebounceFn;
