import { defineComponent } from 'vue';
export default defineComponent({
  name: 'VNodeRender',
  props: {
    vnode: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    return () => props.vnode || '';
  }
});