import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, _mergeProps({
    type: _ctx.type,
    size: _ctx.size,
    loading: _ctx.loading,
    disabled: _ctx.disabled,
    plain: _ctx.plain,
    autofocus: _ctx.autofocus,
    round: _ctx.round,
    circle: _ctx.circle
  }, _ctx.$attrs, {
    class: "t-button",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('click', $event))
  }), {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 16, ["type", "size", "loading", "disabled", "plain", "autofocus", "round", "circle"]);
}