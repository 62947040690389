import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_table_tool_box = _resolveComponent("t-table-tool-box");
  const _component_el_affix = _resolveComponent("el-affix");
  const _component_el_table = _resolveComponent("el-table");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['t-table', _ctx.className, _ctx.showTreeGuideLine ? ' show-tree-guide-line' : null, _ctx.isSelectMode ? 'select-mode' : null])
  }, [_createVNode(_component_el_affix, {
    target: _ctx.affixTarget,
    offset: 60
  }, {
    default: _withCtx(() => [_withDirectives(_createVNode(_component_t_table_tool_box, {
      "table-data": _ctx.data,
      "selected-rows": _ctx.selectedRows,
      "row-key": _ctx.rowKey,
      "is-group": _ctx.isGroup,
      style: _normalizeStyle({
        width: typeof _ctx.tableToolBoxWidth === 'number' ? `${_ctx.tableToolBoxWidth}px` : '100%'
      }),
      "default-batch-selected-text": _ctx.defaultBatchSelectedText,
      onSelectAll: _ctx.handleSelectAll
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "tableToolBox")]),
      _: 3
    }, 8, ["table-data", "selected-rows", "row-key", "is-group", "style", "default-batch-selected-text", "onSelectAll"]), [[_vShow, _ctx.tableToolBoxVisible]])]),
    _: 3
  }, 8, ["target"]), _withDirectives((_openBlock(), _createBlock(_component_el_table, _mergeProps({
    ref: "tableRef"
  }, _ctx.$attrs, {
    class: _ctx.tableClass,
    data: _ctx.data,
    "row-key": _ctx.rowKey,
    "tree-props": _ctx.treeProps,
    indent: 0,
    "row-class-name": _ctx.getRowClassName,
    height: _ctx.height,
    "max-height": _ctx.maxHeight,
    "reserve-selection": true,
    onSelectAll: _ctx.handleSelectAll,
    onSelectionChange: _ctx.handleSelectionChangeDebounce,
    onSelect: _ctx.handleSelect
  }), {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 16, ["class", "data", "row-key", "tree-props", "row-class-name", "height", "max-height", "onSelectAll", "onSelectionChange", "onSelect"])), [[_directive_loading, _ctx.loading]])], 2);
}