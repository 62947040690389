/**
 * @file 包含表格组件的页面时使用的 Hook，包含以下功能：
 * - 对外暴露了常用的表格、分页器、过滤器的方法
 * - 为缓存查询、翻页参数到 url 中提供了通用方案
 * [提示]：类型推断失效时需要重新加载 vscode 窗口
 * @author jiguangrui@baidu.com
 */

import {computed, onMounted, reactive, ref, toRefs, watch, ComputedRef, WritableComputedRef, Ref} from 'vue';
import {LocationQuery, useRoute, useRouter} from 'vue-router';
import {IAPI} from '../api/interface';
import {returnNotEmptyValue, filterObject} from '../utils/object';
import {isNumberString} from '../utils/number';
import {IUseRequestHandler, IUseRequestOption, useRequest} from './useGoodRequest';
import useDebounceFn from '@/base/hooks/useDebounceFn';
import {LOAD_MORE_STATUS_ENUM} from '@/components/TLoadmore/types';

const DEFAULT_PAGE_SIZE = 20;

interface IChangeUrlSearchQueryOption {
    mode?: 'push' | 'replace';
}

interface IFetchTableDataOption {
    // 调用 fetchTableData 引起路由变更时，新路由的处理方式（默认为 push）
    routeMode?: IChangeUrlSearchQueryOption['mode'];
    // 调用 fetchTableData 是否引发路由变更（默认为 false），如果在 useTable 的 options 开启了 disableRouteRecord，该参数无效
    disableChangeUrl?: boolean;
    resetTableData?: boolean;
}

export default function useTable<
    // TODO fix: 这里有问题、当 list 为可选值时会推导出 unknow
    TableData extends any[],
    URL extends keyof IAPI,
    ReqParams extends IAPI[URL]['req'],
    ResData extends IAPI[URL]['res']
>(
    requestHandler: IUseRequestHandler<URL, ReqParams, ResData>,
    options: {
        /**
         * useRequest 的配置项
         */
        useRequestOption?: IUseRequestOption<ReqParams, ResData>;
        /**
         * 过滤参数(请求参数)，注意不要在此传入分页相关的参数
         */
        filterParams?: Partial<ReqParams> | ComputedRef<Partial<ReqParams>> | WritableComputedRef<Partial<ReqParams>>;
        tableDataGetter?: (data: ResData) => TableData;
        totalCountGetter?: (data: ResData) => number;
        /**
         * 请求数据时，分页大小的请求字段
         * @default 'page_size'
         */
        pageSizeReqParam?: string;
        /**
         * 请求数据时，目标页的请求字段
         * @default 'page'
         */
        pageReqParam?: string;
        /**
         * 当从其他页面跳转到当前组件所在页面时触发，读取 url query 时触发，如果没有 url query 则不会触发；
         * 当返回值为 false 的话则不会触发数据请求操作。
         */
        onReadSearchQueryFromUrl?: ((query: LocationQuery) => void) | ((query: LocationQuery) => Promise<boolean|void>);
        /**
         * 在查询参数变化导致 url query 变化时，不保留原有的路由参数
         * @default false
         */
        deleteOriginQueryOnChange?: boolean;
        /**
         * 禁用路由缓存请求参数，设置为 true 后将不会再将分页信息与请求参数写入当前页面 url
         */
        disableRouteRecord?: boolean | Ref<boolean>;
        /**
         * filterParams 以及分页参数改变时，路由的跳转模式
         * @default 'push'
         */
        routeMode?: 'push' | 'replace';
        /**
         * 在向页面 url 中写入参数时，忽略哪些参数不写入
         */
        ignoreRouteParams?: string[] | ((key: string, value: any) => boolean);
        /**
         * 前置函数，允许修改请求参数
         * @param requestParams 原请求参数
         */
        beforeFetchTableData?: (requestParams: any) => any;
        /**
         * 手动触发 fetchTableData，开启后不会在组件挂载时触发 fetchTableData
         */
        manual?: boolean;
        /**
         * 默认的 PageSize 大小
         */
        defaultPageSize?: number;
        /**
         * 默认拉取的页码位置
         */
        defaultCurrentPage?: number;
        /**
         * 在请求的时候不要携带 page 相关的数据
         * @default false
         */
        noPage?: boolean;
        /**
         * 新增数据的模式
         * @default 'replace'
         */
        dataIncreaseMode?: 'replace' | 'append';
    } = {}
) {
    const router = useRouter();

    const route = useRoute();

    const {
        useRequestOption,
        filterParams,
        tableDataGetter,
        totalCountGetter,
        pageSizeReqParam: pageSizeReqParamFromOptions,
        pageReqParam: pageReqParamFromOptions,
        onReadSearchQueryFromUrl,
        deleteOriginQueryOnChange,
        disableRouteRecord,
        beforeFetchTableData,
        manual,
        defaultPageSize,
        defaultCurrentPage,
        routeMode: routeModeFormOptions,
        noPage,
        dataIncreaseMode,
        ignoreRouteParams,
        // FIXME
    } = toRefs(reactive(options || {}));

    const pageSizeReqParam = computed(() => pageSizeReqParamFromOptions?.value || 'page_size');
    const pageReqParam = computed(() => pageReqParamFromOptions?.value || 'page');

    let selectedRow = ref([]);

    // 分页相关
    const currentPage = ref<number>(defaultCurrentPage?.value || 1);
    const currentPageSize = ref<number>(defaultPageSize?.value || DEFAULT_PAGE_SIZE);

    // useRequest 相关
    // @ts-ignore
    const {state, getData} = useRequest(requestHandler, {
        firstTrigger: false,
        ...(useRequestOption?.value as IUseRequestOption<ReqParams, ResData>),
        onError: e => {
            if (typeof useRequestOption?.value?.onError === 'function') {
                useRequestOption.value.onError(e);
            }
            // 如果是 append 模式下拉取数据时出错，页码要回滚一页
            if (dataIncreaseMode?.value === 'append') {
                currentPage.value = Math.max(0, currentPage.value - 1);
            }
        },
    });
    const {data: fetchedData, isLoading: tableLoading, res: fetchedRes} = toRefs(state);
    // 是否禁用当前路由监听器
    const disableRouteWatcher = ref<boolean>(false);
    // 记录当前的路由名称
    const currentRouteName = ref<string | symbol>();
    // 当前是否正在调用 fetchMoreData
    const fetchMoreDataLoading = ref<boolean>(false);

    const tableData = computed<TableData>({
        get: () => {
            let newTableData
                = (typeof tableDataGetter?.value === 'function'
                    ? tableDataGetter.value(fetchedData.value)
                    : fetchedData.value?.list) || [];
            if (dataIncreaseMode?.value === 'append') {
                newTableData = [...(Array.isArray(tableData.value) ? tableData.value : []), ...newTableData];
            }
            return newTableData as TableData;
        },
        set: val => {
            tableData.value = val;
        },
    });

    const totalCount = computed<number>(() => {
        if (typeof totalCountGetter?.value === 'function') {
            return totalCountGetter.value(fetchedData.value) || 0;
        }
        return fetchedData.value?.total ?? 0;
    });

    const hasMoreData = computed<boolean>(() => {
        // Server 接口返回有总数信息
        if (typeof fetchedData.value?.total === 'number') {
            return currentPage.value * currentPageSize.value < fetchedData.value.total;
        }
        // Server 接口返回无总数信息
        let newData
            = (typeof tableDataGetter?.value === 'function'
                ? tableDataGetter.value(fetchedData.value)
                : fetchedData.value?.list) || [];
        if (newData instanceof Array && newData.length <= currentPageSize.value) {
            return true;
        }
        return false;
    });

    const loadMoreStatus = computed<LOAD_MORE_STATUS_ENUM>(() => {
        if (tableLoading.value) {
            return LOAD_MORE_STATUS_ENUM.loading;
        }
        else if (hasMoreData.value) {
            return LOAD_MORE_STATUS_ENUM.pendding;
        }
        return LOAD_MORE_STATUS_ENUM.end;
    });

    /**
     * 改变 url 参数，默认会保留不相关的参数
     */
    const changeUrlSearchQuery = (
        query: LocationQuery,
        option: IChangeUrlSearchQueryOption = {
            mode: 'push',
        }
    ) => {
        const {mode} = option;
        const currentQuery = route.query;
        let newQuery = returnNotEmptyValue({
            ...(deleteOriginQueryOnChange?.value ? {} : currentQuery),
            // 过滤用户会设置不向 url 中记录的参数
            ...filterObject(
                query,
                typeof ignoreRouteParams?.value === 'function'
                    ? ignoreRouteParams.value
                    : key => {
                        if (ignoreRouteParams?.value instanceof Array) {
                            return !ignoreRouteParams.value.includes(key);
                        }
                        return true;
                    }
            ),
        });
        // append 模式下默认不会将 page 与 page_size 写入到 url 中
        if (dataIncreaseMode?.value === 'append') {
            newQuery = filterObject(newQuery, key => ![pageReqParam.value, pageSizeReqParam.value].includes(key));
        }
        if (mode === 'push') {
            router.push({query: newQuery});
        }
        else if (mode === 'replace') {
            router.replace({query: newQuery});
        }
    };
    const {run: changeUrlSearchQueryDebounce} = useDebounceFn(
        (query: LocationQuery, option: IChangeUrlSearchQueryOption, debounceCb: () => void) => {
            changeUrlSearchQuery(query, option);
            if (typeof debounceCb === 'function') {
                debounceCb();
            }
        },
        {wait: 200}
    );

    const handleSelectionChange = value => {
        selectedRow.value = value;
    };

    /**
     * 获取表格数据，默认携带当前 page、page_size、filterParams
     * @returns
     * @param requestParams 请求中携带的参数
     * @param option
     * @returns
     */
    const fetchTableData
        = async (requestParams?: LocationQuery | Partial<ReqParams>, option?: IFetchTableDataOption) => {
            const {
                routeMode = routeModeFormOptions?.value || 'push',
                disableChangeUrl = false,
                resetTableData = false,
            } = option || {};

            if (resetTableData && tableData.value.length) {
                tableData.value.length = 0;
            }

            // 请求分页信息发生变更，内部的分页状态同步变更
            if (requestParams?.[pageReqParam.value] !== undefined
                && !isNaN(Number(requestParams[pageReqParam.value]))) {
                currentPage.value = Number(requestParams[pageReqParam.value]);
            }
            if (
                requestParams?.[pageSizeReqParam.value] !== undefined
            && !isNaN(Number(requestParams[pageSizeReqParam.value]))
            ) {
                currentPageSize.value = Number(requestParams[pageSizeReqParam.value]);
            }

            let searchQuery = {
                ...(noPage?.value
                    ? {}
                    : {[pageReqParam.value]: currentPage.value, [pageSizeReqParam.value]: currentPageSize.value}),
                ...filterParams?.value,
                ...requestParams,
            };
            let _searchQuery = searchQuery;

            // 触发前置函数
            if (typeof beforeFetchTableData?.value === 'function') {
                const {autoValidateParams = true, paramsValidator} = useRequestOption?.value || {};
                if (autoValidateParams) {
                    if (typeof paramsValidator === 'function') {
                        _searchQuery = filterObject(searchQuery, paramsValidator);
                    }
                    else {
                        _searchQuery = returnNotEmptyValue(searchQuery);
                    }
                }
                _searchQuery = beforeFetchTableData.value(_searchQuery);
                if (_searchQuery instanceof Promise) { // 当beforeFetchTableData返回的是一个promise时
                    _searchQuery = await _searchQuery;
                }
            }

            // 是否引起路由变更
            if (!disableChangeUrl && !disableRouteRecord?.value) {
            // 改变路由参数（此时要禁用对 route 的监听）
                disableRouteWatcher.value = true;
                changeUrlSearchQueryDebounce(searchQuery as LocationQuery, {mode: routeMode}, () => {
                // 等待本次组件更新结束后，恢复对 route 的监听
                    setTimeout(() => {
                        disableRouteWatcher.value = false;
                    }, 0);
                });
            }

            // 发起请求，优先使用前置函数返回了修改后的参数（如果有）
            return getData(_searchQuery ?? searchQuery);
        };

    const fetchMoreData = async (
        requestParams?: LocationQuery | Partial<ReqParams>,
        option?: IFetchTableDataOption
    ) => {
        try {
            fetchMoreDataLoading.value = true;
            const res = await fetchTableData(
                {
                    [pageReqParam.value]: (currentPage.value + 1).toString(),
                    ...(requestParams || {}),
                },
                option
            );
            return res;
        }
        catch (error) {
            throw error;
        }
        finally {
            fetchMoreDataLoading.value = false;
        }
    };

    /**
     * 从 url 中读取查询参数并发起 fetch（页面重载、路由变更都会触发）
     */
    const readQueryFromUrlAndFetch = async () => {
        // 从路由获取参数，自动矫正当前分页信息
        const urlQuery = route.query;

        // 使用者须通过 onReadSearchQueryFromUrl 修改组件内的 filter 配置
        // 如果在 onReadSearchQueryFromUrl 方法中修改了请求参数(filterParams)，会自动同步变更 url
        if (typeof onReadSearchQueryFromUrl?.value === 'function') {
            const returnVal = await onReadSearchQueryFromUrl.value(urlQuery);
            if (returnVal === false) {
                return;
            }
        }

        const pageFromQuery = urlQuery?.[pageReqParam.value];
        const pageSizeFromQuery = urlQuery?.[pageSizeReqParam.value];

        // 同步修改 page 与 page_size
        fetchTableData(
            noPage?.value
                ? {}
                : ({
                    [pageReqParam.value]: isNumberString(pageFromQuery)
                        ? Number(pageFromQuery)
                        : Number(currentPage.value),
                    [pageSizeReqParam.value]: isNumberString(pageSizeFromQuery)
                        ? Number(pageSizeFromQuery)
                        : Number(currentPageSize.value),
                } as any),
            {
                routeMode: 'replace',
                resetTableData: dataIncreaseMode?.value === 'append',
            }
        );
    };

    watch(
        route,
        newVal => {
            if (!currentRouteName.value && newVal.name) {
                currentRouteName.value = newVal.name;
            }

            // 如果当前路由页面已经切换至别的页面，让本次监听失效
            if (newVal.name !== currentRouteName.value) {
                return;
            }

            // 路由改变时触发重新读取路由参数并拉取数据
            // 用户前进、后退路由、手动修改路由参数、初次挂载页面都会触发
            if (!disableRouteWatcher.value && !disableRouteRecord?.value) {
                readQueryFromUrlAndFetch();
            }
        },
        {
            immediate: !manual?.value,
        }
    );

    onMounted(() => {
        // 如果禁用路由缓存请求参数
        if (disableRouteRecord?.value && !manual?.value) {
            fetchTableData();
        }
    });

    return {
        // data
        fetchedData, // 获取数据时的原始响数据
        fetchedRes, // 获取数据时的原始响应对象
        currentPage, // 当前页（分页组件使用）
        currentPageSize, // 当前 pageSize（分页组件使用）
        totalCount, // 数据总数量（分页器组件使用）
        tableData, // 获取的表格数据，默认取响应数据的 list 字段（表格组件使用）
        tableLoading, // 数据是否正在加载
        selectedRow, // 批量选择
        // method
        handleSelectionChange,
        fetchMoreDataLoading, // 当前是否正在调用 fetchMoreData
        loadMoreStatus, // 『加载更多的状态』提供给 TLoadmore 组件使用
        fetchTableData, // 获取表格数据，会自动携带 page_size、page、filterParams，并会将相关参数写入当前页面 url
        fetchMoreData, // 手动获取下一页数据
        hasMoreData, // 是否存在下一页数据
    };
}
