import {ISubmitFreetrialReq} from '@/base/api/interface/common';

export enum ACCOUNT_STATUS_ENUM {
    'noLogin' = 'noLogin',
    'notPassport' = 'notPassport',
    'subAccount' = 'subAccount',
    'noTrial' = 'noTrial',
    'trialing' = 'trialing',
    'dataTrialing' = 'dataTrialing',
    'trialExpired' = 'trialExpired',
    'dataTrialingAndTrialExpired' = 'dataTrialingAndTrialExpired',
    'dataTrialExpired' = 'dataTrialExpired',
    'allTrialExpired' = 'allTrialExpired',
};

export enum TRIAL_TYPE_ENUM {
    'trial' = 'trial',
    'dataTrial' = 'dataTrial',
};

export interface ITrialFormData extends Omit<ISubmitFreetrialReq, 'type' | 'area'> {
    area: string[];
};

export interface IToTrialParam {
    type: TRIAL_TYPE_ENUM;
    trigger?: 'loginEntry';
};
