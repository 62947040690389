import { computed, defineComponent, onMounted, onUnmounted, onUpdated, ref, toRefs } from 'vue';
import useDebounceFn from '@/base/hooks/useDebounceFn';
export default defineComponent({
  name: 'EllipsisText',
  props: {
    // 文本的最大行数
    line: {
      type: Number,
      default: 1
    },
    // 文本内容区域的 class
    contentClass: {
      type: [Object, String, Array],
      default: undefined
    },
    // 是否立刻隐藏，【如果在表格中使用需要将其开启】，让其表现与 table 默认显示的 tooltip 保持一致
    hideImmediately: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'top'
    },
    popperClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: undefined
    },
    // 渲染为哪种标签
    tag: {
      type: String,
      default: 'div'
    },
    effect: {
      type: String,
      default: 'dark'
    },
    contentElProps: {
      type: Object,
      default: () => ({})
    },
    // 强制使用通过 -webkit-line-clamp 方式来设置文字溢出的处理方式
    // 备注：如果只展示一行文字，默认使用 text-overflow 与 overflow 方式实现，但是由于 overflow: hidden
    // 会触发 BFC，因此在某些场景下不适合使用（比如 el-description-item）中，此时应该开启 webkit 模式
    webkit: {
      type: Boolean,
      default: false
    },
    showAfter: {
      type: Number,
      default: undefined
    },
    hideAfter: {
      type: Number,
      default: undefined
    }
  },
  setup(props) {
    const {
      line
    } = toRefs(props);
    const contentText = ref('');
    const tooltipDisabled = ref(true);
    const contentDomRef = ref();
    const contentOriginLayout = {
      width: 0,
      height: 0
    };
    const lineMode = computed(() => {
      return line.value > 1 ? 'mutiply' : 'single';
    });
    /**
     * 计算文本内容的原始宽高，用来判断文字是否溢出
     */
    const computeTextOriginLayout = () => {
      const oContent = contentDomRef.value;
      if (oContent) {
        contentText.value = oContent.innerText;
        const range = document.createRange();
        range.setStart(oContent, 0);
        range.setEnd(oContent, oContent.childNodes.length);
        const rangeWidth = range.getBoundingClientRect().width;
        const rangeHeight = range.getBoundingClientRect().height;
        contentOriginLayout.width = rangeWidth;
        contentOriginLayout.height = rangeHeight;
      }
    };
    const {
      run: computeTextOriginLayoutDebounce
    } = useDebounceFn(computeTextOriginLayout, {
      wait: 500
    });
    /**
     * 判断是否需要显示 tooltip
     */
    const judgedShouldShowTooltip = () => {
      // 多行文本模式下，由于容器宽高改变，导致文本发生折行，文本所需的容器宽高会发生改变，所以要重新获取原始宽高
      if (lineMode.value === 'mutiply') {
        computeTextOriginLayoutDebounce();
      }
      if (contentDomRef.value) {
        const computedLayout = contentDomRef.value.getBoundingClientRect();
        const {
          width: currentWith,
          height: currentHeight
        } = computedLayout;
        // 如果当前元素的容器高度小于文本所需要的高度，显示 tooltip
        if (parseInt(currentHeight.toString(), 10) < parseInt(contentOriginLayout.height.toString(), 10) || parseInt(currentWith.toString(), 10) < parseInt(contentOriginLayout.width.toString(), 10)) {
          tooltipDisabled.value = false;
        } else {
          tooltipDisabled.value = true;
        }
      }
    };
    const {
      run: judgedShouldShowTooltipDebounce
    } = useDebounceFn(judgedShouldShowTooltip, {
      wait: 500
    });
    onMounted(() => {
      // 在组件加载完成后计算一次样式
      computeTextOriginLayout();
      // 计算完成后立即判断是否需要显示 tooltip
      judgedShouldShowTooltip();
      // 添加 resize 监听
      window.addEventListener('resize', judgedShouldShowTooltipDebounce);
    });
    onUpdated(() => {
      // 组件更新时要重新计算文本原始宽高
      computeTextOriginLayoutDebounce();
      // 计算完成后立即判断是否需要显示 tooltip
      judgedShouldShowTooltipDebounce();
    });
    onUnmounted(() => {
      // 移除 resize 监听
      window.removeEventListener('resize', judgedShouldShowTooltipDebounce);
    });
    return {
      contentText,
      tooltipDisabled,
      lineMode,
      contentDomRef
    };
  }
});