/**
 * @file 依照UI的按钮组件规范，el-button 所需参数透传
 * 区别于 ElButton：
 * - 默认尺寸为 medium（el-button 默认为 large）
 * @author dingxin02@baidu.com
 */
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TButton',
  components: {},
  inheritAttrs: false,
  emits: ['click'],
  props: {
    /**
     * 类型
     * default 默认按钮 [一般类型，灰边白底]
     * primary 主色调按钮 [产品主色]
     */
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'default'
    },
    loading: Boolean,
    disabled: Boolean,
    plain: Boolean,
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean
  }
});