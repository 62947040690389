import { defineComponent, ref, toRefs, watch, normalizeClass } from 'vue';
/**
 * 尝试加载图片的 src
 */
function tryLoadSrc(src) {
  return new Promise((resolve, reject) => {
    const tmpImgEl = document.createElement('img');
    tmpImgEl.src = src;
    tmpImgEl.onload = () => {
      resolve(src);
    };
    tmpImgEl.onerror = () => {
      reject();
    };
  });
}
/**
 * 基础图片组件，尽量为容器设置宽高，让图片填充容器
 * - 支持图片加载动画
 * - 支持图片加载失败后自动替换为默认图片
 * TODO：
 * - 支持图片懒加载
 */
export default defineComponent({
  name: 'TImage',
  props: {
    containerWidth: {
      type: String,
      default: undefined
    },
    containerHeight: {
      type: String,
      default: undefined
    },
    imgWidth: {
      type: String,
      default: undefined
    },
    imgHeight: {
      type: String,
      default: undefined
    },
    imgClassName: {
      type: [String, Array, Object],
      default: undefined
    },
    imgSrc: {
      type: String,
      required: true
    },
    /**
     * 本地默认占位图片，如果 imgSrc 是空值，图片地址将会显示为该值
     */
    placeholderImgSrc: {
      type: String,
      default: undefined
    },
    /**
     * 当图片加载失败时显示的图片，如果不额外指定，默认使用 placeholderImgSrc
     */
    failedImgSrc: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    const {
      imgSrc,
      placeholderImgSrc,
      failedImgSrc
    } = toRefs(props);
    const finalSrc = ref(placeholderImgSrc.value || '');
    const setFinalSrc = async src => {
      try {
        await tryLoadSrc(src);
        finalSrc.value = src;
      } catch {
        finalSrc.value = failedImgSrc.value || placeholderImgSrc.value || imgSrc.value;
      }
    };
    watch(imgSrc, async newVal => {
      if (newVal) {
        setFinalSrc(newVal);
      }
    }, {
      immediate: true
    });
    watch(placeholderImgSrc, (newVal, oldVal) => {
      if (oldVal && finalSrc.value === oldVal || !finalSrc.value) {
        finalSrc.value = newVal || '';
      }
    });
    return {
      finalSrc,
      normalizeClass
    };
  }
});