/**
 * 公司默认logo样式集
 */
export const COMPANY_DEFAULT_LOGO_STYLES: Array<{
    backgroundColor: string;
    color: string;
}> = [
    {
        backgroundColor: '#258EB7',
        color: '#B2D7E6',
    },
    {
        backgroundColor: '#E56B9B',
        color: '#F6CCDC',
    },
    {
        backgroundColor: '#A4C791',
        color: '#DFEBD9',
    },
    {
        backgroundColor: '#6A8CBE',
        color: '#CBD7E8',
    },
    {
        backgroundColor: '#4FAFBB',
        color: '#C2E3E7',
    },
    {
        backgroundColor: '#B5E926',
        color: '#E4F7B2',
    },
    {
        backgroundColor: '#8AAADC',
        color: '#D6E1F3',
    },
    {
        backgroundColor: '#B5AC82',
        color: '#E4E2D4',
    },
    {
        backgroundColor: '#A598BA',
        color: '#E0DBE7',
    },
    {
        backgroundColor: '#F1D935',
        color: '#FAF2B8',
    },
];
