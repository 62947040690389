import {PickPathReq} from '../../interface';
import * as PATHS from '.';
import {FETCH_API} from '@/base/http';

export function getBuyerDbCompanyFilterItems(params: PickPathReq<typeof PATHS.GET_BUYERDB_COMPANY_FILTER_ITEMS_API>) {
    return FETCH_API.get(PATHS.GET_BUYERDB_COMPANY_FILTER_ITEMS_API, {
        params,
        baseURL: process.env.VUE_APP_WAIMAO_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

export function getBuyerDbCompanies(params: PickPathReq<typeof PATHS.GET_BUYERDB_COMPANIES>) {
    return FETCH_API.get(PATHS.GET_BUYERDB_COMPANIES, {
        params,
        baseURL: process.env.VUE_APP_WAIMAO_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

export function getUsBuyerdbCompanyDetail(params: PickPathReq<typeof PATHS.GET_US_BUYERDB_COMPANY_DETAIL>) {
    return FETCH_API.get(PATHS.GET_US_BUYERDB_COMPANY_DETAIL, {
        params,
        baseURL: process.env.VUE_APP_WAIMAO_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}

export function getBuyerdbCompanyCustomsData(params: PickPathReq<typeof PATHS.GET_BUYERDB_COMPANY_CUSTOMS_DATA>) {
    return FETCH_API.get(PATHS.GET_BUYERDB_COMPANY_CUSTOMS_DATA, {
        params,
        baseURL: process.env.VUE_APP_WAIMAO_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}
