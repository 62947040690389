import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d20928e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['t-image']),
    style: _normalizeStyle({
      width: _ctx.containerWidth,
      height: _ctx.containerHeight
    })
  }, [_createElementVNode("img", {
    class: _normalizeClass(_ctx.normalizeClass(_ctx.imgClassName)),
    style: _normalizeStyle({
      width: _ctx.imgWidth,
      height: _ctx.imgHeight
    }),
    src: _ctx.finalSrc,
    alt: ""
  }, null, 14, _hoisted_1)], 4);
}