/**
 * 美国买家数据库·公司列表-部分检索条件
 * @docs https://yapi.baidu-int.com/project/17619/interface/api/1702993
 */
export const GET_BUYERDB_COMPANY_FILTER_ITEMS_API = '/buyerdb/company/filterItems';

/**
 * 美国买家数据库·公司列表
 * @docs https://yapi.baidu-int.com/project/17619/interface/api/1702904
 */
export const GET_BUYERDB_COMPANIES = '/buyerdb/company/companies';

/**
 * 美国买家数据库·公司详情
 * @docs https://yapi.baidu-int.com/project/17619/interface/api/1725120
 */
export const GET_US_BUYERDB_COMPANY_DETAIL = '/buyerdb/company/detail';

/**
 * 美国买家数据库·公司详情页-海关数据接口
 * @docs https://yapi.baidu-int.com/project/17619/interface/api/1804284
 */
export const GET_BUYERDB_COMPANY_CUSTOMS_DATA = '/buyerdb/company/customsData';
