import "core-js/modules/es.array.push.js";
/**
 * 通用表格组件，封装自 el-table，对其进行以下优化：
 * - 优化 el-table 样式
 * - 统一空列表页面
 * - 实现树形表格多选的级联操作
 * - 多选数据后，表格头部转变为操作区
 * - 保留原 el-tbale 的各种组件 props 以及 ref
 */
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, toRefs, watch, watchEffect } from 'vue';
import TTableToolBox from './TTableToolBox.vue';
import { dfsTree } from '@/base/utils/tree';
import useDebounceFn from '@/base/hooks/useDebounceFn';
export default defineComponent({
  name: 'TTable',
  inheritAttrs: false,
  props: {
    class: {
      type: String,
      default: undefined
    },
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: String,
      default: undefined
    },
    treeProps: {
      type: Object,
      default: undefined
    },
    tableClass: {
      type: String,
      default: undefined
    },
    rowClassName: {
      type: [String, Function],
      default: undefined
    },
    // 是否显示树形数据的引导线（目前仅支持两级数据的引导线显示）
    showTreeGuideLine: {
      type: Boolean,
      default: false
    },
    isGroup: {
      type: Boolean,
      default: true
    },
    height: [String, Number],
    maxHeight: [String, Number],
    affixTarget: {
      type: String,
      default: '.t-table'
    },
    defaultBatchSelectedText: {
      type: String,
      default: '已选择{n}条数据'
    }
  },
  components: {
    TTableToolBox
  },
  emits: {
    selectAll: null,
    selectionChange: null,
    select: null
  },
  setup(props, context) {
    const {
      data,
      rowKey,
      treeProps,
      class: className,
      rowClassName
    } = toRefs(props);
    const selectedRows = ref([]);
    const tableRef = ref(null);
    // 存放在树形结构的数据中，每个子节点的最后一个节点的 id
    const lastChildrenNodeIds = ref([]);
    // 用户在 type 为 selection 的 el-table-column 组件中传入的 selectable 方法
    const tableRowSelectable = ref(() => true);
    const tableToolBoxWidth = ref(0);
    // 是否有选中数据
    const hasSelectedData = computed(() => {
      return !!(selectedRows.value instanceof Array && selectedRows.value.length);
    });
    // 是否开启树形数据配置
    const isTreeDataMode = computed(() => {
      var _treeProps$value;
      return !!(rowKey.value && (_treeProps$value = treeProps.value) !== null && _treeProps$value !== void 0 && _treeProps$value.children);
    });
    // 表格是否为可选模式
    const isSelectMode = computed(() => {
      var _tableRef$value;
      const columns = (_tableRef$value = tableRef.value) === null || _tableRef$value === void 0 || (_tableRef$value = _tableRef$value.store) === null || _tableRef$value === void 0 || (_tableRef$value = _tableRef$value.states) === null || _tableRef$value === void 0 || (_tableRef$value = _tableRef$value.columns) === null || _tableRef$value === void 0 ? void 0 : _tableRef$value.value;
      if (columns instanceof Array) {
        return columns.some(column => column.type === 'selection');
      }
      return false;
    });
    // 树形结构的数据中，父节点与子节点之间的映射 Map，优化树形选择器查找父节点的响应速度
    const dataNodeMap = computed(() => {
      const nodeMap = new Map();
      // 只有开启树形数据配置是才创建映射
      if (isTreeDataMode.value) {
        var _treeProps$value2;
        const _rowKey = rowKey.value;
        const _children = (_treeProps$value2 = treeProps.value) === null || _treeProps$value2 === void 0 ? void 0 : _treeProps$value2.children;
        dfsTree(data.value, _children, node => {
          const nodeId = node === null || node === void 0 ? void 0 : node[_rowKey];
          // 如果当前节点没有在 nodeMap 中，则说明本节点没有父节点
          if (!nodeMap.has(nodeId)) {
            nodeMap.set(nodeId, {
              parent: null
            });
          }
          // 遍历当前节点的子节点，将其子节点的父节点指针指向当前节点
          if ((node === null || node === void 0 ? void 0 : node[_children]) instanceof Array) {
            node[_children].forEach(child => {
              nodeMap.set(child === null || child === void 0 ? void 0 : child[_rowKey], {
                parent: node
              });
            });
          }
        });
      }
      return nodeMap;
    });
    // tableToolBox 是否是可见状态
    const tableToolBoxVisible = computed(() => !!(selectedRows.value instanceof Array && selectedRows.value.length));
    /**
     * 切换目标行的选中状态
     */
    const toggleSelect = (row, selected) => {
      var _tableRef$value2;
      if (typeof ((_tableRef$value2 = tableRef.value) === null || _tableRef$value2 === void 0 ? void 0 : _tableRef$value2.toggleRowSelection) === 'function') {
        nextTick(() => {
          var _tableRef$value3;
          (_tableRef$value3 = tableRef.value) === null || _tableRef$value3 === void 0 || _tableRef$value3.toggleRowSelection(row, selected);
        });
      }
    };
    const handleSelectAll = selection => {
      // 如果开启树形数据模式，点击多选时会选中所有的子节点
      if (isTreeDataMode.value) {
        // tableData 第一层只要有在 selection 里面就是全选
        const isSelect = selection.some(selectedItem => {
          const tableDataIds = data.value.map(dataItem => dataItem === null || dataItem === void 0 ? void 0 : dataItem[rowKey.value]);
          return tableDataIds.includes(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem[rowKey.value]);
        });
        if (isSelect) {
          var _treeProps$value$chil, _treeProps$value3;
          dfsTree(data.value, (_treeProps$value$chil = (_treeProps$value3 = treeProps.value) === null || _treeProps$value3 === void 0 ? void 0 : _treeProps$value3.children) !== null && _treeProps$value$chil !== void 0 ? _treeProps$value$chil : 'children', (item, index) => {
            if (tableRowSelectable.value(item, index)) {
              toggleSelect(item, true);
            }
          });
        } else {
          var _treeProps$value$chil2, _treeProps$value4;
          dfsTree(data.value, (_treeProps$value$chil2 = (_treeProps$value4 = treeProps.value) === null || _treeProps$value4 === void 0 ? void 0 : _treeProps$value4.children) !== null && _treeProps$value$chil2 !== void 0 ? _treeProps$value$chil2 : 'children', (item, index) => {
            if (tableRowSelectable.value(item, index)) {
              toggleSelect(item, false);
            }
          });
        }
      }
      context.emit('selectAll', selection);
    };
    const handleSelectionChange = selection => {
      selectedRows.value = selection;
      context.emit('selectionChange', selection);
    };
    // 通过防抖限制 selectionChange 事件的触发频率，避免 ui 层出现数值闪烁问题
    const {
      run: handleSelectionChangeDebounce
    } = useDebounceFn(handleSelectionChange, {
      wait: 4
    });
    const handleSelect = (selection, row) => {
      // 如果是树形解构的数据，在选中某一项时，需要关联选中或取消选中其子项与父项
      if (isTreeDataMode.value) {
        var _treeProps$value5;
        const _rowKey = rowKey.value;
        const _children = (_treeProps$value5 = treeProps.value) === null || _treeProps$value5 === void 0 ? void 0 : _treeProps$value5.children;
        const isSelect = selection.some(item => (item === null || item === void 0 ? void 0 : item[_rowKey]) === (row === null || row === void 0 ? void 0 : row[_rowKey]));
        // 如果是选中某一项，全选其子项
        if (isSelect && (row === null || row === void 0 ? void 0 : row[_children]) instanceof Array) {
          nextTick(() => {
            row[_children].filter((item, index) => tableRowSelectable.value(item, index)).forEach(item => {
              toggleSelect(item, true);
            });
          });
        }
        // 如果是取消选中某项
        else {
          var _dataNodeMap$value$ge;
          // 取消选中其所有子项
          if ((row === null || row === void 0 ? void 0 : row[_children]) instanceof Array) {
            row[_children].forEach(item => {
              toggleSelect(item, false);
            });
          }
          // 如果其有父节点，取消选中其父节点
          const parent = (_dataNodeMap$value$ge = dataNodeMap.value.get(row === null || row === void 0 ? void 0 : row[_rowKey])) === null || _dataNodeMap$value$ge === void 0 ? void 0 : _dataNodeMap$value$ge.parent;
          if (parent) {
            toggleSelect(parent, false);
          }
        }
      }
      context.emit('select', selection, row);
    };
    const getRowClassName = record => {
      let rowClassNames = '';
      // 为每一行附加 rowClassName
      if (isTreeDataMode.value && rowKey.value && tableRef.value) {
        var _tableRef$value$store, _tableRef$value4;
        const {
          row
        } = record;
        const _rowKey = row[rowKey.value];
        // 从 el-table 组件示例的 states 中获取 treeData，来判断当前行的各种状态
        const treeDataMap = (_tableRef$value$store = (_tableRef$value4 = tableRef.value) === null || _tableRef$value4 === void 0 || (_tableRef$value4 = _tableRef$value4.store) === null || _tableRef$value4 === void 0 || (_tableRef$value4 = _tableRef$value4.states) === null || _tableRef$value4 === void 0 || (_tableRef$value4 = _tableRef$value4.treeData) === null || _tableRef$value4 === void 0 ? void 0 : _tableRef$value4.value) !== null && _tableRef$value$store !== void 0 ? _tableRef$value$store : {};
        if (Object.prototype.hasOwnProperty.call(treeDataMap, _rowKey)) {
          const currentRowTreeData = treeDataMap[_rowKey];
          // 判断当前行是否展开
          if (currentRowTreeData !== null && currentRowTreeData !== void 0 && currentRowTreeData.expanded) {
            rowClassNames = 'row-expanded';
          }
        }
        // 当前行是否是子节点且是该层级下最后一个子节点
        if (lastChildrenNodeIds.value.includes(_rowKey)) {
          rowClassNames += ' last-child-node';
        }
      }
      // 拼接用户传入的 rowClassName
      if (typeof rowClassName === 'function' || typeof rowClassName.value === 'function') {
        const rowClassNameFunc = typeof rowClassName === 'function' ? rowClassName : rowClassName.value;
        const funcResult = rowClassNameFunc(record);
        if (typeof funcResult === 'string') {
          rowClassNames += ' ' + funcResult;
        }
      } else if (typeof rowClassName === 'string') {
        rowClassNames += ' ' + rowClassName;
      }
      return rowClassNames;
    };
    /**
     * 获取 tTable 宽度
     */
    const setTableToolBoxWidth = () => {
      var _tableRef$value5;
      const tableEl = (_tableRef$value5 = tableRef.value) === null || _tableRef$value5 === void 0 ? void 0 : _tableRef$value5.$el;
      if (tableToolBoxVisible.value && tableEl) {
        tableToolBoxWidth.value = tableEl.getBoundingClientRect().width;
      }
    };
    const {
      run: setTableToolBoxWidthDebounce
    } = useDebounceFn(setTableToolBoxWidth, {
      wait: 50
    });
    watch(() => {
      var _tableRef$value6;
      return (_tableRef$value6 = tableRef.value) === null || _tableRef$value6 === void 0 || (_tableRef$value6 = _tableRef$value6.store) === null || _tableRef$value6 === void 0 || (_tableRef$value6 = _tableRef$value6.states) === null || _tableRef$value6 === void 0 || (_tableRef$value6 = _tableRef$value6.treeData) === null || _tableRef$value6 === void 0 ? void 0 : _tableRef$value6.value;
    }, newVal => {
      // 记录树形数据中每个节点的最后一个子节点
      if (isTreeDataMode.value && rowKey.value && tableRef.value) {
        const ids = [];
        Object.values(newVal).forEach(item => {
          if ((item === null || item === void 0 ? void 0 : item.children) instanceof Array && item.children.length) {
            ids.push(item.children[item.children.length - 1]);
          }
        });
        lastChildrenNodeIds.value = ids;
      }
    });
    // 当 tableToolBox 显示时，要计算其宽度
    watch(tableToolBoxVisible, newVal => {
      if (newVal) {
        setTableToolBoxWidth();
      }
    });
    watchEffect(() => {
      if (tableRef.value) {
        var _tableRef$value$$slot;
        const elTableSlots = (_tableRef$value$$slot = tableRef.value.$slots) !== null && _tableRef$value$$slot !== void 0 && _tableRef$value$$slot.default ? tableRef.value.$slots.default() : [];
        const defaultSlot = elTableSlots.find(slot => slot.key === '_default');
        if (defaultSlot) {
          // 获取传入的所有 ElTableColumns 实例
          const elTableColumns = defaultSlot.children;
          if (elTableColumns instanceof Array) {
            var _selectionRow$props;
            // 获取复选框的列
            const selectionRow = elTableColumns.find(column => {
              var _column$props, _column$type;
              return (column === null || column === void 0 || (_column$props = column.props) === null || _column$props === void 0 ? void 0 : _column$props.type) === 'selection' || (column === null || column === void 0 || (_column$type = column.type) === null || _column$type === void 0 ? void 0 : _column$type.name) === 'TTableSelectColumn';
            });
            if (typeof (selectionRow === null || selectionRow === void 0 || (_selectionRow$props = selectionRow.props) === null || _selectionRow$props === void 0 ? void 0 : _selectionRow$props.selectable) === 'function') {
              // 提取复选框列组件传入的 selectable 方法
              tableRowSelectable.value = selectionRow.props.selectable;
            }
          }
        }
      }
    });
    onMounted(() => {
      nextTick(() => {
        setTableToolBoxWidth();
      });
      window.addEventListener('resize', setTableToolBoxWidthDebounce);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setTableToolBoxWidthDebounce);
    });
    return {
      // data
      selectedRows,
      hasSelectedData,
      className,
      isSelectMode,
      tableToolBoxVisible,
      tableToolBoxWidth,
      // methods
      handleSelectAll,
      handleSelectionChangeDebounce,
      handleSelect,
      getRowClassName,
      // ref
      tableRef
    };
  }
});