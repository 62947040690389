/**
 * 过滤器
 * @author liuqigeng01
 * @date 2021-11-29
 */

/**
 * 格式化数字。
 * 1.将大于某个值的数字转换成小数+单位形式，例：23600 => '2.4W'；
 * 2.四舍五入格式化指定位数的小数；
 * 3.整数部分每3位逗号分割；
 *
 * @params {params: {value, divisor, unit, fixed}}
 * @param {value: number} 要格式化的数值，>= 0
 * @param {divisor: number} 除数，> 0
 * @param {unit: string} 单位，如 '万(W)'
 * @param {fixed: number} 转换成小数+单位后，保留小数点的位数
 * @param {fullFixed: number} 没转换成小数+单位时，保留的小数点位数
 * @param {comma: boolean} 整数部分是否每3位逗号分割
 * @return {newValue: string} 格式化后的值 如 1.2W
 */
interface IFormatNumberToShort {
    value: number;
    divisor?: number;
    unit?: string;
    fixed?: number;
    fullFixed?: number;
    comma?: boolean;
}
export function formatNumberToShort(
    {
        value,
        divisor = 10000,
        unit = 'W',
        fixed = 1,
        fullFixed = -1,
        comma = false,
    }: IFormatNumberToShort): string {
    if (typeof value !== 'number') {
        return `${value}`;
    }
    let newValue = value;
    let decimalCount = fullFixed;
    let suffix = '';
    if (newValue >= divisor) {
        newValue = newValue / 10000;
        decimalCount = fixed;
        suffix = unit;
    }
    if (decimalCount > 0) {
        newValue = parseFloat(newValue.toFixed(decimalCount));
    }
    return `${(comma ? newValue.toLocaleString() : newValue)}${suffix}`;
}

/**
 * 是否是数字字符串
 * @param {string} val 判定值
 * @returns {boolean}
 */
export function isNumberString(val: any) {
    if (typeof val === 'string') {
        const numberedString = Number(val);
        return !Number.isNaN(numberedString);
    }
    return false;
}

/**
 * 是否是有效数字
 * @param {string} val 判定值
 * @returns {boolean}
 */
export function isValidNumber(val: any) {
    return typeof val === 'number' && !isNaN(val);
}

/**
 * 保留几位小数（整数不保留）
 * @param {number} val 数值
 * @param {string} unit 单位
 * @param {number} fixed 保留位数
 * @returns {undefined || string}
 */
export function fixNumber(val: number, unit = '', fixed = 2) {
    const re = parseFloat(Number(val).toFixed(fixed));
    return isNaN(re) ? undefined : `${re}${unit}`;
}

/**
 * 计算字节个数
 * @param {string} text 字符串
 * @returns {number}
 */
export function accountChar(text: string) {
    // 一个汉字占两个字节,汉字标点占3个字节
    const char = text.replace(/[\u4e00-\u9fa5]/g, 'aa').replace(
        // eslint-disable-next-line max-len
        /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,
        'bbb');
    return char.length;
}

/**
 * 字节单位转换
 * @param {number} bytes 字节数值
 * @param {number} fixedNum 保留位数
 * @returns string
 */
export function bytesToSize(bytes: number, fixedNum: number = 1) {
    if (bytes === 0) {
        return '0 B';
    }
    let k = 1024;
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return sizes[i] ? `${fixNumber(bytes / Math.pow(k, i), '', fixedNum)} ${sizes[i]}` : '-';
}

