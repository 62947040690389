import {AxiosError} from 'axios';
import {reactive, toRefs, UnwrapRef} from 'vue';
import {IAPI} from '../api/interface';
import {FETCH_API, IAxiosRequestConfig, IAxiosResponse} from '../http';
import {filterObject, returnNotEmptyValue} from '../utils/object';

interface IUseRequestState<T extends any, K extends any> {
    res?: IAxiosResponse<T>;
    data: T;
    formattedData: K;
    isLoading: boolean;
    hasError: boolean;
}

export interface IUseRequestOption<ReqParams, ResData, FormattedResData = any> {
    params?: ReqParams;
    method?: 'get' | 'post'; // 调用接口的方法，默认为 'get'，当 requestHandler 传入方法时无效
    config?: {
        initData?: ResData;
        axiosConfig?: IAxiosRequestConfig;
        silent?: boolean;
    };
    autoValidateParams?: boolean; // 调用 getData 时是否自动删除掉无效的参数，默认为 true
    paramsValidator?: (key: any, value: any) => boolean; // 自定义请求参数校验器，返回 false 时该参数会被过滤掉
    firstTrigger?: boolean;
    onSuccess?: (res: IAxiosResponse<ResData>) => void;
    onError?: (error: Error) => void;
    onFinally?: () => void;
    dataFormatter?: (data: ResData) => FormattedResData; // 将返回的数据格式化
    keepLastRequest?: boolean; // 同一个 useRequest 在短时间内发出多个请求时，依照请求发起的先后顺序，仅保留最后一个请求的结果，默认为 true
}

type FilterUnknownType<T, K> = unknown extends T ? K : T;

export type IUseRequestHandler<URL, ReqParams, ResData> =
    | URL
    | ((params: ReqParams, config: IAxiosRequestConfig) => Promise<IAxiosResponse<ResData>>);

export const useRequest = <
    T extends any,
    URL extends keyof IAPI,
    ReqParams extends IAPI[URL]['req'],
    ResData extends FilterUnknownType<IAPI[URL]['res'], T>,
    FormattedResData
>(
    requestHandler: IUseRequestHandler<URL, ReqParams, ResData>,
    options?: IUseRequestOption<ReqParams, ResData, FormattedResData>
) => {
    const {
        firstTrigger = true,
        autoValidateParams = true,
        paramsValidator,
        dataFormatter,
        keepLastRequest = true.valueOf,
        method = 'get',
    } = options || {};
    const requestQueue: number[] = [];

    const state = reactive<IUseRequestState<ResData | undefined, FormattedResData | undefined>>({
        res: undefined,
        data: options?.config?.initData,
        formattedData:
            typeof dataFormatter === 'function' && options?.config?.initData
                ? dataFormatter(options?.config?.initData)
                : undefined,
        isLoading: false,
        hasError: false,
    });

    /**
     * 当 requestHandler 是一个请求 URL 时，该方法自动为该 URL 封装好一个请求方法
     * 当前 requestHandler 是一个请求方法时，该方法调用的是其本身
     *
     * 此外该方法会对请求做如下处理：
     * 1. 自动过滤调空的请求参数（由构造 useRequest 时的 autoValidateParams 参数决定）
     * 2. 自动为每个请求标记一个时间序号，保证 state 中的数据是最后一个请求发出后的响应结果（由构造 useRequest 时的 keepLastRequest 参数决定）
     * @param p 请求参数
     * @param c AxiosRequestConfig
     */
    const getData = async (p?: ReqParams, c?: IAxiosRequestConfig) => {
        try {
            const params = typeof p === 'undefined' ? options?.params || {} : p;
            const config = typeof c === 'undefined' ? {...options?.config?.axiosConfig} : c;

            state.isLoading = true;

            // 记录当前请求序号
            const requestId = new Date().valueOf();
            requestQueue.push(requestId);

            // 发起请求
            let reqParams = params;

            // 检查是否需要过滤参数
            if (autoValidateParams) {
                // 启用自定义校验器
                if (typeof paramsValidator === 'function') {
                    reqParams = filterObject(params, paramsValidator);
                }
                // 启用默认校验器
                else {
                    reqParams = returnNotEmptyValue(params);
                }
            }

            let res: any = null;

            if (typeof requestHandler === 'string') {
                if (method === 'post') {
                    res = await FETCH_API.post(requestHandler, {
                        ...config,
                        data: reqParams,
                    });
                }
                else {
                    res = await FETCH_API.get(requestHandler, {
                        ...config,
                        params: reqParams,
                    });
                }
            }
            else if (typeof requestHandler === 'function') {
                res = await requestHandler(reqParams as ReqParams, config);
            }

            // 根据请求序号检查请求是否位于队列末尾（是否是最新的）
            if (requestQueue[requestQueue.length - 1] === requestId || !keepLastRequest) {
                requestQueue.length = 0; // 清空请求队列
                state.data = res.data as UnwrapRef<ResData>;
                if (typeof dataFormatter === 'function') {
                    state.formattedData = dataFormatter(res.data as ResData) as UnwrapRef<FormattedResData>;
                }
                state.res = res as UnwrapRef<IAxiosResponse<ResData>>;

                state.isLoading = false;

                if (options?.onSuccess) {
                    options?.onSuccess(res as IAxiosResponse<ResData>);
                }
            }
        }
        catch (error) {
            state.isLoading = false;
            state.hasError = true;
            if (options?.onError) {
                options?.onError(error as AxiosError);
            }
        }
        finally {
            if (options?.onFinally) {
                options.onFinally();
            }
        }
    };

    if (firstTrigger) {
        getData();
    }

    return {
        state,
        refsState: toRefs(state),
        getData,
    };
};
