import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$showAfter, _ctx$hideAfter;
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock(_component_el_tooltip, _mergeProps({
    content: _ctx.contentText
  }, _ctx.$attrs, {
    disabled: _ctx.disabled === undefined ? _ctx.tooltipDisabled : !!_ctx.disabled,
    "show-after": (_ctx$showAfter = _ctx.showAfter) !== null && _ctx$showAfter !== void 0 ? _ctx$showAfter : _ctx.hideImmediately ? 0 : undefined,
    "hide-after": (_ctx$hideAfter = _ctx.hideAfter) !== null && _ctx$hideAfter !== void 0 ? _ctx$hideAfter : _ctx.hideImmediately ? 0 : undefined,
    placement: _ctx.placement,
    "popper-class": `ellipsis-text__tooltip-popper${_ctx.hideImmediately ? ' hide-immediately' : ''}${_ctx.popperClass ? ` ${_ctx.popperClass}` : ''}`
  }), {
    default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps({
      ref: "contentDomRef",
      class: ['ellipsis-text', _ctx.lineMode === 'mutiply' || _ctx.webkit ? 'ellipsis-text__mutiply' : 'ellipsis-text__single', _ctx.contentClass],
      style: _ctx.lineMode === 'mutiply' || _ctx.webkit ? {
        WebkitLineClamp: _ctx.line
      } : undefined
    }, _ctx.contentElProps), {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
      _: 3
    }, 16, ["class", "style"]))]),
    _: 3
  }, 16, ["content", "disabled", "show-after", "hide-after", "placement", "popper-class"]);
}